import { __decorate } from "tslib";
import { Component, Vue, Watch, Prop, } from 'vue-property-decorator';
import { WorkPlatformService } from '@/sevices';
import { H3List } from '@h3/thinking-ui';
let mWorkPlatFormNoticesList = class mWorkPlatFormNoticesList extends Vue {
    constructor() {
        super(...arguments);
        this.page = 1;
        this.finished = false;
        this.loading = false;
        this.list = [];
        this.todoType = '1';
        this.appLoading = false;
        this.flag = false;
    }
    showToast(show) {
        if (show) {
            this.$toast.show({
                icon: 'loading',
                text: '加载中',
            });
        }
        else {
            this.$toast.hide();
        }
    }
    // 切换todotype
    checkTodoType(param) {
        this.todoType = param;
        this.resetParams();
    }
    /**
     * 滚动加载
     * @val: 检测 下拉加载
     * @type: 检测 切换tab加载,展示loading
     */
    async nextPage(val, type = '') {
        // 控件问题，如果数据没撑满一页的时候会发起两次请求，所以拦截一下
        if (this.flag) {
            return;
        }
        this.flag = true;
        setTimeout(() => {
            this.flag = false;
        }, 100);
        // end
        this.loading = true;
        if (type === 'checkTab') {
            this.appLoading = true;
        }
        const data = await this.getTodoList();
        const itemCount = await this.getWorkItemCount();
        // console.log('data', data);
        this.page++;
        this.list.push(...data.data);
        this.finished = this.list.length >= (data.total || 0);
        this.loading = false;
        if (val === 'refresh') {
            this.$toast.show({
                text: '刷新成功',
                duration: 1000,
            });
        }
        if (type === 'checkTab') {
            this.appLoading = false;
        }
        if (this.searchTxt === '') {
            this.$emit('todoTotal', itemCount.workItemCount);
            this.$emit('waitRead', itemCount.circulateItemCount || 0);
        }
    }
    async getWorkItemCount() {
        const res = await WorkPlatformService.getWorkItemCount();
        if (res.success) {
            return res.data;
        }
        return {};
    }
    /**
     * 重置接口参数
     */
    resetParams() {
        this.list = [];
        this.page = 1;
        this.finished = false;
    }
    // 下拉刷新
    async onRefresh() {
        await this.resetParams();
        // setTimeout(()=>{
        this.nextPage('refresh');
        // },200)
    }
    /**
     * 获取通知
     * @todoType: 通知类型
     */
    getTodoList() {
        const params = {
            todoType: this.todoType,
            page: this.page,
            size: 20,
            instanceName: this.searchTxt,
        };
        if (this.todoType === '3' || this.todoType === '4') {
            params.startTime = this.startTime;
            params.endTime = this.endTime;
        }
        return WorkPlatformService.getTodoList(params).then((res) => {
            if (res.success) {
                return res;
            }
            else {
                return {};
            }
        });
    }
    /**
     * 如果流程发生变化，则刷新数据
     */
    reloadPage(evt) {
        if (evt.data === 'reload' && evt.origin === window.location.origin) {
            this.onRefresh();
        }
    }
    created() {
        window.addEventListener('message', this.reloadPage, false);
    }
};
__decorate([
    Prop({ default: '' })
], mWorkPlatFormNoticesList.prototype, "searchTxt", void 0);
__decorate([
    Prop({ default: '' })
], mWorkPlatFormNoticesList.prototype, "startTime", void 0);
__decorate([
    Prop({ default: '' })
], mWorkPlatFormNoticesList.prototype, "endTime", void 0);
__decorate([
    Watch('appLoading')
], mWorkPlatFormNoticesList.prototype, "showToast", null);
mWorkPlatFormNoticesList = __decorate([
    Component({
        name: 'mWorkPlatFormNoticesList',
        components: {
            H3List,
            listItemTodo: () => import('./list-item-todo'),
        },
    })
], mWorkPlatFormNoticesList);
export default mWorkPlatFormNoticesList;
